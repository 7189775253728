import { RETURN_LABEL_TYPE } from 'constants/amethystEnums';

const determineReturnLabelTypes = returnLabelTypes => returnLabelTypes.map(type => determineReturnLabelType(type));

const determineReturnLabelType = returnLabelType => RETURN_LABEL_TYPE[returnLabelType] || 'UNKNOWN_RETURN_LABEL_TYPE';

export const evReturnInitiateClick = ({ returnInitiateSource }) => ({
  returnInitiateClick: {
    returnInitiateSource: returnInitiateSource
  }
});

export const evNumberOfBoxesOnReturnSubmit = ({ numberOfBoxes }) => ({
  numberOfBoxesOnReturnSubmit: {
    numberOfBoxes: numberOfBoxes
  }
});

export const evReturnTransportationOptions = ({ availableReturnLabels, displayedReturnLabels, arePickupReturnsAuthorized }) => ({
  returnTransportationOptions: {
    availableReturnLabelTypes: determineReturnLabelTypes(availableReturnLabels),
    displayedReturnLabelTypes: determineReturnLabelTypes(displayedReturnLabels),
    upsPickupsAuthorized: arePickupReturnsAuthorized
  }
});

export const evZawReturn = ({ step, scenario, subsidyValue }) => {
  const event = {
    zawReturn: {
      step,
      scenario
    }
  };

  if (subsidyValue !== undefined && subsidyValue !== null) {
    event.zawReturn.subsidyValue = subsidyValue;
  }

  return event;
};

export const evSubmitReturnClick = ({ returnLabel, startTime }) => {
  const MILLISECOND_TO_SECOND = 1000;
  const calculateTimeSpentInSecond = startTime => Math.floor((new Date().getTime() - startTime) / MILLISECOND_TO_SECOND);
  return {
    submitReturnClick: {
      returnLabelType: [determineReturnLabelType(returnLabel)],
      returnDetails: [{ timeSpent: calculateTimeSpentInSecond(startTime) }]
    }
  };
};

export const pvReturnConfirmation = ({ returnLabel }) => ({
  returnConfirmationPageView: {
    returnLabelType: determineReturnLabelType(returnLabel)
  }
});
