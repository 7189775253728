import { GIFTCARD_UNAVAILABLE_VALUE } from 'constants/appConstants';
import { ZAW_API_RETRY_COUNT } from 'constants/zaw';
import { isValidEmailAddress } from 'helpers/index';
import {
  RECEIVE_ACCOUNT_CONTENT,
  RECEIVE_ACCOUNT_INFO,
  RECEIVE_GIFT_CARD_BALANCE,
  RECEIVE_REDEEM_GIFT_CARD_FAILURE,
  RECEIVE_REDEEM_GIFT_CARD_SUCCESS,
  RECEIVE_ZAW_PROMOTIONS,
  REMOVE_ITEM_FOR_CANCELLATION_OR_RETURN,
  REQUEST_ACCOUNT_INFO,
  REQUEST_GIFT_CARD_BALANCE,
  REQUEST_REDEEM_GIFT_CARD,
  REQUEST_ZAW_PROMOTIONS,
  RESET_CANCEL_OR_RETURN_ITEMS,
  RESET_ZAW_STATE,
  RESTORE_ACCOUNT_STATE,
  SELECT_ITEM_FOR_CANCELLATION_OR_RETURN,
  SET_ITEM_NOTIFICATION,
  SET_ITEMS_FOR_EXCHANGE_OR_RETURN,
  SET_PREVIOUS_ORDER_ACTION,
  UPDATE_GIFT_CARD_REDEEM_CODE
} from 'constants/reduxActions';
import { CONSTRAINT_VIOLATIONS_MESSAGES } from 'constants/constraintViolations';
import { sha256 } from 'helpers/ads';
import { logError } from 'middleware/logger';
import { authenticationErrorCatchHandlerFactory, err, setError } from 'actions/errors';
import {
  claimGiftCard,
  getCustomer,
  getGiftCard,
  getSymphonySlots,
  getZawPromotions,
  logoutCustomer,
  subscribeToStockNotificationZSub
} from 'apis/mafia';
import {
  ERROR_NOT_AUTHENTICATED,
  fetchApiNotAuthenticatedMiddleware,
  fetchErrorMiddleware,
  fetchErrorMiddlewareAllowAllErrorsWithServerLogging
} from 'middleware/fetchErrorMiddleware';
import { prependAppRoot } from 'history/AppRootUtils';
import { trackError } from 'helpers/ErrorUtils';
import { setSessionCookies } from 'actions/session';
import { processHeadersMiddleware } from 'middleware/processHeadersMiddlewareFactory';
import { getAmethystPageType } from 'helpers/analytics';
import zaw from 'utils/zaw';
import { isValidMartyPath } from 'helpers/LocationUtils';
import { selectMafiaConfig } from 'selectors/environment';

export function requestAccountInfo() {
  return {
    type: REQUEST_ACCOUNT_INFO
  };
}

export function receiveAccountInfo(customerInfo) {
  return {
    type: RECEIVE_ACCOUNT_INFO,
    customerInfo
  };
}

export function requestGiftCardBalance() {
  return {
    type: REQUEST_GIFT_CARD_BALANCE
  };
}

export function receiveGiftCardBalance(giftCardBalance) {
  return {
    type: RECEIVE_GIFT_CARD_BALANCE,
    giftCardBalance
  };
}

export function requestRedeemGiftCard(sourcePage) {
  return {
    type: REQUEST_REDEEM_GIFT_CARD,
    sourcePage
  };
}

export function receiveRedeemGiftCardSuccess(giftCardBalance) {
  return {
    type: RECEIVE_REDEEM_GIFT_CARD_SUCCESS,
    giftCardBalance
  };
}

export function receiveRedeemGiftCardFailure(giftCardRedeemError) {
  return {
    type: RECEIVE_REDEEM_GIFT_CARD_FAILURE,
    giftCardRedeemError
  };
}

export function updateGiftCardRedeemCode(giftCardRedeemCode) {
  return {
    type: UPDATE_GIFT_CARD_REDEEM_CODE,
    giftCardRedeemCode
  };
}

// this does not support multiple EGCs, consider using lineItemId if we eventually support
export function setItemNotification(idArr) {
  const id = idArr[0];
  return {
    type: SET_ITEM_NOTIFICATION,
    id
  };
}

export function selectItemForCancellationOrReturn(item) {
  return {
    type: SELECT_ITEM_FOR_CANCELLATION_OR_RETURN,
    item
  };
}

export function setItemsForCancellationOrReturn(selectedItems) {
  return {
    type: SET_ITEMS_FOR_EXCHANGE_OR_RETURN,
    selectedItems
  };
}

export function removeItemForCancellationOrReturn(item) {
  return {
    type: REMOVE_ITEM_FOR_CANCELLATION_OR_RETURN,
    item
  };
}

export function receiveAccountContent(content) {
  return {
    type: RECEIVE_ACCOUNT_CONTENT,
    content
  };
}

export function requestZawPromotions() {
  return {
    type: REQUEST_ZAW_PROMOTIONS
  };
}

export function receiveZawPromotions(zawPromotions, zawPromotionsStatus) {
  return {
    type: RECEIVE_ZAW_PROMOTIONS,
    zawPromotions,
    zawPromotionsStatus
  };
}

export function resetZawState() {
  return {
    type: RESET_ZAW_STATE
  };
}

export const resetCancelOrReturnItems = () => ({
  type: RESET_CANCEL_OR_RETURN_ITEMS
});

export const setPreviousOrderAction = previousOrderAction => ({
  type: SET_PREVIOUS_ORDER_ACTION,
  previousOrderAction
});

export const restoreAccountState = previousAccountState => ({
  type: RESTORE_ACCOUNT_STATE,
  previousAccountState
});

export function fetchAccountInfo(returnTo) {
  return (dispatch, getState) => {
    dispatch(requestAccountInfo());
    const state = getState();
    const {
      cookies,
      router: { location }
    } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return getCustomer(mafiaConfig, cookies)
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(({ customerInfo }) => {
        dispatch(receiveAccountInfo(customerInfo));
        return customerInfo;
      })
      .catch(e => {
        // Optional auth error handler since some pages don't have any other API calls but still require authentication
        if (returnTo) {
          return authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot(isValidMartyPath(returnTo) ? returnTo : '', location))(e);
        }
        // ignore auth errors here, because at the time of writing this call is always used in conjunction with a more specific
        // fetch call that should do the redirecting.
        if (e.id !== ERROR_NOT_AUTHENTICATED) {
          dispatch(setError(err.GENERIC, e));
        }
      });
  };
}

export function fetchMyAccountContent(getOrderStatuses = getSymphonySlots) {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return getOrderStatuses(mafiaConfig, { pageName: 'account', pageLayout: 'your-account' }, cookies)
      .then(fetchErrorMiddleware)
      .then(response => {
        dispatch(receiveAccountContent(response?.slotData));
      })
      .catch(err => {
        trackError('NON-FATAL', 'Failed to fetch Symphony order statuses', err);
      });
  };
}

export function fetchGiftCardBalance() {
  return (dispatch, getState) => {
    dispatch(requestGiftCardBalance());

    const state = getState();
    const {
      cookies,
      router: { location }
    } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return getGiftCard(mafiaConfig, cookies)
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddlewareAllowAllErrorsWithServerLogging('API-myAccount-giftCard'))
      .then(({ balance = GIFTCARD_UNAVAILABLE_VALUE }) => {
        // if giftcards are enabled and getting a 400 or 404 default to unavailable
        dispatch(receiveGiftCardBalance(balance));
      })
      .catch(authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot('/account', location)));
  };
}

const shouldRetryZawRequest = (err, remainingRetries) => {
  const isTimeoutOrOtherGenericError = err.status === 500;
  return isTimeoutOrOtherGenericError && remainingRetries > 1;
};

export function fetchZawPromotionsAndAddOrderAmounts(promotionsIds, ordersMap, remainingRetries = ZAW_API_RETRY_COUNT) {
  return (dispatch, getState) => {
    const emptyPromotionsData = {};

    if (promotionsIds.length === 0) {
      dispatch(receiveZawPromotions(emptyPromotionsData));
      return;
    }

    dispatch(requestZawPromotions());

    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return getZawPromotions(mafiaConfig, promotionsIds, cookies)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(response => {
        const promotions = zaw.mapNewEndpointToOldFormat(response?.eligiblePromos);
        const promotionsWithAmounts = zaw.addRawPromoAmountsFromOrderToPromos(promotions, Object.values(ordersMap));
        const promotionsStatus = response.status === 204 ? response.message : null;
        return dispatch(receiveZawPromotions(promotionsWithAmounts, promotionsStatus));
      })
      .catch(err => {
        if (shouldRetryZawRequest(err, remainingRetries)) {
          return dispatch(fetchZawPromotionsAndAddOrderAmounts(promotionsIds, ordersMap, remainingRetries - 1));
        } else {
          return dispatch(receiveZawPromotions(emptyPromotionsData));
        }
      });
  };
}

export function fireProductNotifyMe(data, apiNotifyMeSubscription = subscribeToStockNotificationZSub) {
  return function (dispatch, getState) {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return apiNotifyMeSubscription(mafiaConfig, data, cookies)
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchErrorMiddleware)
      .then(() => {
        const id = Object.values(data)[0];
        dispatch(setItemNotification(id));
      })
      .catch(e => dispatch(setError(err.CART, e)));
  };
}

export function redeemGiftCard(giftCardRedeemCode, element) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      cookies,
      router: { location },
      pageView: { pageType }
    } = state;
    const mafiaConfig = selectMafiaConfig(state);
    const sourcePage = getAmethystPageType(pageType);

    dispatch(requestRedeemGiftCard(sourcePage));

    return claimGiftCard(mafiaConfig, giftCardRedeemCode, cookies)
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(({ balance, cvs }) => {
        const {
          account: { giftCard }
        } = CONSTRAINT_VIOLATIONS_MESSAGES;

        if (cvs.length) {
          // Display gift card failure error message
          const giftCardRedeemError = giftCard[cvs[0].name];
          dispatch(receiveRedeemGiftCardFailure(giftCardRedeemError));
        } else {
          // Update gift card balance
          dispatch(receiveRedeemGiftCardSuccess(balance));
        }

        // Focus gift card redeem input field
        element.focus();
      })
      .catch(authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot('/account', location)));
  };
}

export function logoutAsCustomer() {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return logoutCustomer(mafiaConfig, cookies);
  };
}

export function emailHasher(file) {
  // Normalize email address so that it is lowercase without any whitespace
  const normalizeEmail = string =>
    string
      .trim()
      .toLowerCase()
      .replace(/\+[^@]*@/g, '@');
  // Normalizes an email address value and then checks to make sure it is a valid
  // email address
  const emailNormalizer = record => {
    const email = normalizeEmail(record);
    if (isValidEmailAddress(email)) {
      return email;
    } else {
      logError(`${record} is not a valid email.`);
    }
  };
  try {
    if (Array.isArray(file)) {
      const arrayOfRecords = [];
      file.forEach(record => {
        arrayOfRecords.push(sha256(emailNormalizer(record)));
      });
      return arrayOfRecords;
    } else if (typeof file === 'string') {
      return sha256(emailNormalizer(file));
    } else {
      logError(`Error: typeof argument should be array or string ${file}`);
    }
  } catch (err) {
    logError(err);
  }
}
